import Icons from "../../definitions/Icons.js"

export default class PublicQRCode extends HTMLElement {
  constructor() {
    super()
  }
  connectedCallback() {
    this.triggerElement = this.querySelector("a")
    this._styles()
    this._listenEvents()
  }

  _listenEvents() {
    this.triggerElement.addEventListener(
      "click",
      this._previewQRCode.bind(this)
    )
  }

  _styles() {
    const style = document.createElement("style")
    style.textContent = `
            .card-container {
                display: flex;
                justify-content: center;
                align-items: center;
                position: fixed;
                inset: 0;
                background: rgba(0, 0, 0, 0.5);
                z-index: 50;
                animation: fade 1s ease forwards;
            }

            .qr-card {
                background: #fff;
                padding: 20px;
                width: 80%;
                max-width: 400px;
                position: relative;
                animation: appear 1s ease forwards;
                justify-content: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-radius: 2rem;
            }

            .qr-card h2 {
                min-height: 2rem;
                padding-top: 2rem;
            }

            .qr-card img {
                border-radius: 50%;
                width: 10rem;
                height: 10rem;
                object-fit: cover;
            }

            qr-code-styling {
                width: 100%;
            }

            .close {
              position: absolute;
              top: 1rem;
              right: 1rem;
              appearance: none;
              color: black;
              outline: none;
            }

            .close svg {
              color: black;
              fill: black;
              stroke: black;
            }

            @keyframes fade {
                0% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }

            @keyframes unfade {
                0% {
                  opacity: 1;
                }
                100% {
                  opacity: 0;
                }
            }

            @keyframes appear {
                0% {
                    transform: scale(0.5) rotateY(-180deg);
                    opacity: 0;
                }
                100% {
                    transform: scale(1) rotateY(0deg);
                    opacity: 1;
                }
            }

            @keyframes disappear {
                0% {
                  transform: scale(1) rotateY(0deg);
                  opacity: 1;
                }
                100% {
                    transform: scale(0.5) rotateY(-180deg);
                    opacity: 0;
                }
            }
        `
    this.appendChild(style)
  }

  _previewQRCode(e) {
    e.preventDefault()
    this._templatePreview()
  }

  _templatePreview() {
    this._styles()
    this.container = document.createElement("div")
    this.container.classList.add("card-container")
    //${window.atom_dict.img && window.atom_dict.img[0]?.img ? `<img src="${window.atom_dict.img[0]?.img}" />` : ''}
    this.container.innerHTML = `
      <div class="qr-card boonddescription">
        <button class="close">${Icons.close}</button>
        <h2>${window.atom_dict.name}</h2>
        <qr-code-styling data-render="true" data-user-url="${window.user_url}" data-action="download"></qr-code-styling>
      </div>
    `
    document.body.appendChild(this.container)
    this.closeButton = this.container.querySelector(".close")
    this.cardElement = this.container.querySelector(".qr-card")
    this.closeButton.addEventListener("click", this._handleClose.bind(this))
  }

  _handleClose() {
    this.closeButton.removeEventListener("click", this._handleClose.bind(this))
    this.container.addEventListener(
      "animationend",
      this._removeElement.bind(this)
    )
    this.cardElement.style.animation = "disappear 1s ease forwards"
    this.container.style.animation = "unfade 1s ease forwards"
  }

  _removeElement() {
    this.container.remove()
  }
}

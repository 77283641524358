import "../css/index.css"
import "../css/profile_public.css"
import "../css/motions.css"
import "../css/richtext.css"
import "../css/tabs.css"
import "./src/indexpublic.js"

import initializeBoondsPublic from "./boonds_public"

initializeBoondsPublic()
